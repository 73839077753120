export default {
  colors: {
    default: "#344675",
    primary: "#42b883",
    info: "#1d8cf8",
    danger: "#aa1100",
    teal: "#00d6b4",
    blue: "#33D2FF",
    white: "#fff",
    yello: "#eaf30c",
    mainGradient: [
      "rgba(76, 211, 150, 0.1)",
      "rgba(76, 211, 150, 0.0)",
      "rgba(76, 211, 150, 0.0)",
      "rgba(76, 211, 150, 0.0)"
    ],
    dangerGradient: [
      "rgba(255, 0, 0, 0.2)",
      "rgba(255, 0, 0, 0.1)",
      "rgba(255, 0, 0, 0.4)",
      "rgba(255, 0, 0, 0.8)"
    ],
    primaryGradient: [
      "rgba(76, 211, 150, 0.3)",
      "rgba(76, 211, 150, 0.1)",
      "rgba(76, 211, 150, 0.1)",
      "rgba(76, 211, 150, 0.1)"
    ],
    // dangerGradient: [
    //   "rgba(76, 211, 150, 0.1)",
    //   "rgba(53, 183, 125, 0)",
    //   "rgba(119,52,169,0)"
    // ],
    emptyGradient: [
      "rgba(76, 211, 150, 0)",
      "rgba(76, 211, 150,0)",
      "rgba(76, 211, 150,0)",
      "rgba(76, 211, 150, 0)"
    ],
    whiteGradient: ["rgb(254, 255, 253,0.1)", "rgb(254, 255, 253,0)", "rgb(254, 255, 253,0)", "rgb(254, 255, 253,0)"],
    yelloGradient: [
      "rgb(234, 243, 12,0.1)",
      "rgb(234, 243, 12,0)",
      "rgb(234, 243, 12,0)",
      "rgb(234, 243, 12,0)",],
    blueGradient: ["rgba(51, 210, 255, 0.1)",
      "rgba(51, 210, 255, 0)",
      "rgba(51, 210, 255, 0)",
      "rgba(51, 210, 255, 0)",],

  }
};
